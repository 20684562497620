.container-main {
  display: flex;
  align-items: center;
  justify-content: space-around;

  padding: 12px 20px;

  border-bottom: 1px solid #EDEDED;
}

.item-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    font-size: 12px;
  }
}