@mixin media($size) {
  @media (max-width: #{$size}) {
      @content;
  }
}

@mixin flex($justify: center, $align: center, $wrap: nowrap, $direction: row) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
}

@mixin font($color: $black, $fz: 18px, $fw: 400, $td: none) {
  color: $color;
  font-size: $fz;
  font-weight: $fw;
  text-decoration: $td;
}

