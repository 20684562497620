.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .pagination {
    display: flex;
    align-items: center;
    list-style: none;
    outline: none;
    user-select: none;
  }

  .pagination > .active > a {
    color: white;
    border: #62B24A;
  }
  .pagination > li > a {
    padding: 7px 18px;
    outline: none;
    cursor: pointer;
    border: 1px solid gray;
    color: black;
  }

  .pagination > li > a:hover {
    border: 1px solid #62B24A;
  }

  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    background-color: #62B24A;
    border: 1px solid #62B24A;
    outline: none;
    color: black;
  }

  .pagination > li.previous > a,
  .pagination > li.next > a {
    border: 1px solid white;
    padding: 0px;
  }

  .arrow-left-icon,
  .arrow-right-icon {

    border: 1px solid gray;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    padding: 10.5px 18px;
    margin-bottom: 2px;

    &:hover {
      border: 1px solid #62B24A;
      transition: all 0.3s ease;
    }
  }

  .arrow-right-icon {
    transform: rotate(180deg);
  }

  @media (max-width: 570px) {
    .pagination > li > a {
      padding: 5px 8px;
      outline: none;
      cursor: pointer;
      border: 1px solid gray;
    }

    .arrow-left-icon,
    .arrow-right-icon {
      padding: 3px 7px;
    }
  }
}
