$white: #ffffff;
$cream: #FCFCFC;

$gray: #666666;
$lightGray: #e6e6e6;
$black: #333333;

$lightGreen: #35B537;
$green: #6dc53e;
$lime: #a5ef2e;
$darkGreen: #088D0A;

$red: #d46262;

$darkRed: #B11607;
$burgundy: #811F16;


$laptop: '1440px';
$ipad: '1366px';