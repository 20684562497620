.input-with-tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 712px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;

    &.focused {
        border-color: green;
    }
}

.textarea-metaDescription{
    max-width: 712px;
    width: 100%;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
}

.textarea-metaDescription::placeholder{
    font-size: 16px;
    color: #aaa;
}

.textarea-metaDescription:focus {
    outline: none;
    border-color: green;
  }

.label-meta {
    font-size: 16px;
    font-weight: 600;

    margin-bottom: 4px;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 10px;
}

.tag {
    display: flex;
    align-items: center;
    background-color: green;
    color: #fff;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.tag-close-icon {
    margin-left: 10px;
    cursor: pointer;
}

input[type="text"] {
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
}

input[type="text"]::placeholder {
    color: #aaa;
}