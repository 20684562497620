.table-container-header {
  display: flex;
  align-items: center;
  justify-content: space-around;

  padding: 20px;

  border-bottom: 1px solid #EDEDED;

  @media (max-width: 700px) {
    display: none;
  }
}

.item-block {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.text-header-table {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}