.button {
    background: #62B24A;
    border-radius: 8px;
    border: none;
    color: white;

    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    border: 1px solid white;

    padding: 14px 35px;

    cursor: pointer;

    transition: all 0.3s;
}

.button:hover{
  background-color: white;
  border: 1px solid #62B24A;

  color: #62B24A;

  transition: all 0.3s;
}