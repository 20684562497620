.container {
  &-core {
    display: flex;
    justify-content: space-between;
    max-width: 700px;
  }
  &-left-block{
    margin-right: 50px;
  }
}
