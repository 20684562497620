.modal-container {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.blur-container {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 10;

  border-radius: 20px;
  max-height: 97vh;

  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}