.ant-calendar-picker-input.ant-input {
    border: 1px solid #E1E1E1;
    border-radius: 8px;

    line-height: 1.5;
    height: 44px;
}

.ant-time-picker-large .ant-time-picker-input {
    height: 44px;
    padding: 6px 11px;
    font-size: 16px;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    line-height: 1.5;
}

.form-container {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.input {
    border: 1px solid #E1E1E1;
    border-radius: 8px;

    padding: 10px 12px;

    width: 342px !important;
    height: 44px;
}

.label {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
}

.form-block {
    display: flex;
    flex-direction: column;

    margin-right: 28px;
}

.delete-img {
    margin-top: 25px;

    cursor: pointer;

    width: 20px;
    height: 20px;
}