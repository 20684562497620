.tabLanguage-container {
    display: flex;
}

.language-block{
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 75px;
    max-height: 40px;
    border-radius: 10px;
    background-color: #666666;

    margin-right: 10px;
    padding: 5px 23px;

    cursor: pointer;
    color: white;

    &.active {
        background-color: #6dc53e;
    }
}