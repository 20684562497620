.tableWrapper {
    margin-top: 32px;
}

.filtered-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 32px;
}

.selector {
    padding: 10px 12px;

    width: 194px;
    height: 44px;

    border: 1px solid #E1E1E1;
    border-radius: 8px;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.concert-image {
    width: 78px;
    height: 44px;
}

.text-table {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.icons{
    display: flex;
}

.icon{
    margin-right: 20px;
    cursor: pointer;

    width: 24px;
    height: 24px;
}