.sidebar {
  width: 20%;
  min-width: 225px;

  padding: 20px;

  @include flex(flex-start, flex-start, nowrap, column);

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;

  transform: translateX(-100%);

  box-shadow: 0 0px 4px 2px $lightGray;

  background-color: $cream;

  transition: all 0.3s ease;

  &.active {
    visibility: visible;
    transform: translateX(0);
  }

  &-logo {
    align-self: center;

    & img {
      width: 100px;
      height: auto;
    }
  }

  &-nav {
    width: 100%;
    overflow-y: hidden;
    margin-top: 20px;

    padding-bottom: 26px;

    border-bottom: 1px solid #CACACA;

    @include flex(flex-start, flex-start, nowrap, column);
  }

  &-link {
    width: 100%;

    margin: 2px 0;
    padding: 3px 5px 5px 15px;

    border-radius: 25px;

    @include flex(flex-start);

    @include font($gray, 16px, 500);

    background-color: transparent;

    transition: all 0.1s linear;

    &:hover {
      background-color: darken($lightGray, 10%);
      opacity: 0.9;
    }

    &.active {
      background-color: darken($lightGray, 10%);
      opacity: 1;
    }

    & img {
      width: 24px;
      height: 24px;

      margin-right: 10px;
    }
  }
}

.select-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-top: 30px;
  padding-bottom  : 14px;
}

.select-headline {
  display: flex;
  align-items: center;

  cursor: pointer;
}

.arrow-img {
  width: 10px;
  height: 10px;
  cursor: pointer;

  transition: transform 0.3s ease;
}

.arrow-img.active {
  transform: rotate(180deg);
}

.select-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  margin-left: 10px;
  margin-right: 90px;
}

.items {
  display: flex;
  flex-direction: column;
}

.item-link {
  margin: 9px 0 7px 14px;
  padding: 3px 5px 5px 15px;

  border-radius: 25px;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333333;

  text-decoration: none;

  &.active {
    background-color: darken($lightGray, 10%);
    opacity: 1;
  }
}