.wrapper {
    padding: 41px 20px 50px 0;

    margin-bottom: 50px;

    padding-left: 3%;

    transition: 0.3s all;

    width: 100vw;
    height: 100vh;

    &.shorter {
        transition: 0.3s all;
        padding-left:23%;
    }
}

.lending-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-header-block{
    display: flex;
    justify-content: space-between;

    margin-top: 25px;
}

.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}

.title-h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;

    margin-top: 20px;
    margin-bottom: 24px;
}

.photo-wrapper {
    display: flex;

    margin-bottom: 28px;
}

.photo-block {
    margin-right: 28px;
}

.form {
    display: flex;
    flex-direction: column;

    margin-bottom: 48px;
}

.label {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    margin-bottom: 8px;
}

.textarea {
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    max-width: 712px;
    width: 100%;
    height: 250px;

    padding: 8px 12px;
    font-weight: 400;
    font-size: 14px;
}

.error-message-description{
    font-size: 13px;
    color: red;
    margin-top: -43px;
}

.gallery-wrapper {
    max-width: 712px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    margin-bottom: 30px;
}

.gallery-block {
    margin-right: 28px;
}

.tabs-wrapper {
    max-width: 712px;
    width: 100%;
    display: flex;

    margin-top: 32px;
}

.tab-block {
    text-align: center;
    width: 50%;

    cursor: pointer;

    padding-bottom: 9px;

    border-bottom: 1px solid #E1E1E1;

    &.active {
        border-bottom: 3px solid #9CD38B;

        transition: all 0.1s;

        color: #62B24A;
    }
}

.tab-block:hover {
    border-bottom: 3px solid #9CD38B;

    transition: all 0.1s;

    color: #62B24A;
}


.tab-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.line {
    padding-top: 50px;
}

.ofline-menu {
    margin-top: 40px;
    margin-bottom: 40px;
}

.file-input {
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    font-size: 12px;
    padding: 11px 16px;
}

.file-input::placeholder {
    color: red;
    font-size: 20px;
}

.file-bold {
    font-weight: 600;
    color: black;

    margin-left: 2px;
}

.file-bold:hover{
    color:black
}

.file-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    margin-right: 47px;
}

.file-block {
    display: flex;
    align-items: center;
}