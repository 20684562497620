@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

$border-color: #62B24A;
$background-color: #FFF1E2;
$plus-icon-url: "./../../assets/icons/upload.svg";

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .photo-input {
    display: none;
  }
}


.container-header {
  width: 100%;
  display: flex;
  justify-content: space-between;

  margin-bottom: 12px;
}


.container-photo-picker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
}

.photo-picker-block {
  position: relative;
  display: flex;

  &.has-photo {
    .edit-photo-inside,
    .delete-photo-inside {
      display: block;
    }
  }

  .delete-photo-inside,
  .edit-photo-inside {
    display: none;
  }
}

.edit-photo {
  cursor: pointer;
}

.image-button {
  position: relative;
  background: #F0FBED;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;

  cursor: pointer;

  border: 1px dashed #62B24A;
  border-radius: 8px;

  &.with-photo {
    .plus-icon {
      display: none;
    }
  }
}

.text {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #62B24A;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.hidden {
    display: none;
  }
}

.plus-icon {
  transition: all 0.4s ease;
  width: 15px;
  height: auto;
  content: url($plus-icon-url);
}

.delete-photo-inside {
  position: absolute;
  cursor: pointer;

  top: 12px;
  right: 12px;

  z-index: 99;
}

.edit-photo-inside {
  position: absolute;
  cursor: pointer;

  top: 12px;
  right: 50px;

  z-index: 99;

  &.active{
    right: 12px;
  }
}