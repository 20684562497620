.google-autocomplete {
  width: 350px;

  padding: 10px;

  font-size: 16px;

  border: 1px solid #e6e6e6;
  border-radius: 5px;

  outline: none;

  background-color: #FCFCFC;

  transition: all .2s ease;

  cursor: pointer;

  &.invalid {
    border-color: #d46262;
  }

  &-wrapper {
    width: 350px;

    margin: 10px 0;
  }
}

.date-picker {
  width: 350px;

  padding: 10px;

  font-size: 16px;

  border: 1px solid #e6e6e6;
  border-radius: 5px;

  outline: none;

  background-color: #FCFCFC;

  transition: all .2s ease;

  cursor: pointer;

  &.invalid {
    border-color: #d46262;
  }

  &-wrapper {
    width: 350px;

    margin: 10px 0;
  }
}
