.modal-header {
    display: flex;
    justify-content: space-between;
}

.photo-container {
    margin-top: 22px;
    margin-bottom: 20px;
}

.close {
    cursor: pointer;
}

.error-message{
    font-size: 14px;
    color: red;
    margin-top: -20px;
}

.form-container-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0;
}

.styled-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    margin-bottom: 8px;
}

.forms-date {
    display: flex;
    justify-content: space-between;
}

.styled-input {
    border: 1px solid #E1E1E1;
    border-radius: 8px;

    padding: 10px 12px;

    width: 448px;
    height: 44px;
}

.styled-textarea {
    border: 1px solid #E1E1E1;
    border-radius: 8px;

    width: 448px;
    height: 180px;

    padding: 8px 12px;
}

.footer-modal {
    display: flex;
    justify-content: space-between;
}

.styled-button {
    background: #62B24A;
    border-radius: 8px;
    border: 2px solid #62B24A;

    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 218px;
    height: 44px;

    padding: 16px 28px;

    outline: none;

    cursor: pointer;

    &.hover {
        background-color: transparent;
        color: #62B24A;
    }
}


.styled-button:hover {
    background: white;
    color: #62B24A;

    transition: all 0.3s;

    &.hover {
        background-color: #62B24A;
        color: white;
    }
}