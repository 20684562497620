.s-input-control {
  position: relative;
}

.search-image {
  position: absolute;
  pointer-events: none;
  top: 10px;
  left: 10px;
  z-index: 3000;
}

.inputSearch-wrapper {
  position: relative;
  background-color: white;
}

.my-input {
  position: relative;
  z-index: 1;
  width: 315px;
  height: 44px;
  padding: 12px 13px 12px 33px;
  border-radius: inherit;
  text-align: left;
  border: 1px solid #e1e1e1;
  border-radius: 8px;

  outline: none;
  font-weight: 400;

  line-height: 140%;

  transition: background-color 0.2s ease-in-out, border 0.25s ease-in-out,
    color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;

  &::-webkit-input-placeholder {
    color: gray;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: gray;
    opacity: 1;
  }
  &:-moz-placeholder {
    color: gray;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: gray;
    opacity: 1;
  }

  &::placeholder {
    padding-left: 5px;
  }

  &:hover {
    box-shadow: 0 0 5px gray;
  }
  &:focus {
    box-shadow: none;
  }
  &:hover,
  &:focus {
    border-color: #62b24a;
  }

  &:active {
    border-color: gray;
  }
}
