.page {
  &-notification {
    &-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      margin: 8px 0;
    }

    &-title {
      font-size: 15px;
      font-weight: 600;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-body {
      font-size: 13px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &-wrapper {
    width: 100%;
    height: 100vh;

    padding: 0 0 0 20px;

    overflow: hidden;

    transition: all 0.3s ease;

    &.shorter {
      padding-left: 20%;

      @include media("1100px") {
        padding-left: 225px;
      }
    }

    &.footer {
      padding-bottom: 60px;
    }
  }

  &-container {
    width: 100%;
    height: 100vh;

    padding: 40px 40px 20px 20px;

    overflow-y: auto;

    position: relative;
  }

  &-footer {
    width: 100%;
    height: 70px;

    @include flex();

    // position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    box-shadow: 0 0px 4px 2px $lightGray;

    background-color: $cream;
  }

  &-header {
    width: 100%;

    margin-bottom: 70px;

    @include flex(space-between);
  }

  &-title {
    @include font($black, 20px, 700);
  }

  &-btns-container {
    @include flex();

    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 1;

    border-radius: 5px;

    // background-color: $cream;
  }

  &-modal {
    &-wrapper {
      @include flex();

      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9999;

      visibility: hidden;

      opacity: 0;

      background-color: rgba(0, 0, 0, 0.6);

      transition: all 0.3s ease;

      cursor: pointer;

      &.active {
        visibility: visible;

        opacity: 1;
      }
    }

    &-content {
      padding: 90px 120px 20px;

      border-radius: 5px;

      background-color: $cream;

      cursor: default;

      position: relative;
    }
  }

  &-preloader {
    @include flex();

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: transparent;

    & img {
      width: 100px;
      height: auto;

      animation: pulse 1s ease infinite alternate;

      @keyframes pulse {
        0% {
          transform: scale(1);
        }

        100% {
          transform: scale(1.3);
        }
      }
    }

    &.relative {
      position: relative;
    }
  }

  .flex {
    display: flex;
    width: 100%;
  }
}

.dates-flex {
  width: 100%;
  display: flex;
  grid-gap: 10px;
  & > .input-wrapper:last-child {
    padding-top: 18px;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  // bottom: 0;
  color: transparent;
  cursor: pointer;
  height: 20px;
  // left: 0;
  position: absolute;
  // right: 0;
  // top: 0;
  width: 350px;
}

.page-btns-container {
  position: fixed;
}

.arrows {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  & > img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1350px) {
  .page-btns-container {
    flex-direction: column;
  }

  .btn--default {
    margin-right: 15px;
  }
  .btn--default--red {
    margin-top: 20px;
  }
}
