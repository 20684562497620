.container-footer {
  padding: 20px 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  box-shadow: 0px 10px 22px rgba(135, 135, 135, 0.16);

  @media (max-width: 1000px) {
    flex-direction: column;
  }
}

.limit-block {
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    margin-top: 20px;
  }
}

.text-block {
  font-size: 12px;
  color: gray;

  @media (max-width: 1000px) {
    margin-bottom: 20px;
  }
}

.text-left {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-right: 12px;
}

.text-right {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 12px;
}